// generated: Tue May 07 2024 16:22:00 GMT+0200 (GMT+02:00)
// code for 'functions.ts' file
// user functions for addin


import { saveCellAddress, sendRequest, getDataToInsert } from "../../helpers";


/**
 * Returns a list of datasets with available API calls and limits.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} [product] Product code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Api usage
 * @customfunction
*/
async function ApiUsage(apikey: string, update_frequency: string, display_titles: boolean, product?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/api_usage';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    product: product
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("ApiUsage", ApiUsage);


/**
 * Returns the dividends calendar from Benzinga.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} ticker Filter by ticker symbol
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter events by end time using a UNIX timestamp
 * @param {number} [start_at] Filter events by start time using a UNIX timestamp
 * @param {string} [date] Specifies the exact date to get the data for
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Dividends calendar
 * @customfunction
*/
async function BenzingaDividendsCalendar(apikey: string, update_frequency: string, display_titles: boolean, ticker: string, order?: string, end_at?: number, start_at?: number, date?: string, page_size?: number, page?: number, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/benzinga/dividends_calendar';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    ticker: ticker, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    date: date, 
    page_size: page_size, 
    page: page, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("BenzingaDividendsCalendar", BenzingaDividendsCalendar);


/**
 * Returns the earnings calendar from Benzinga.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} ticker Filter by ticker symbol
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter events by end time using a UNIX timestamp
 * @param {number} [start_at] Filter events by start time using a UNIX timestamp
 * @param {string} [date] Specifies the exact date to get the data for
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Earnings calendar
 * @customfunction
*/
async function BenzingaEarningsCalendar(apikey: string, update_frequency: string, display_titles: boolean, ticker: string, order?: string, end_at?: number, start_at?: number, date?: string, page_size?: number, page?: number, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/benzinga/earnings_calendar';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    ticker: ticker, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    date: date, 
    page_size: page_size, 
    page: page, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("BenzingaEarningsCalendar", BenzingaEarningsCalendar);


/**
 * Returns IPO data from Benzinga.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter events by end time using a UNIX timestamp
 * @param {number} [start_at] Filter events by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [exchange] Exchange where instrument is traded
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return IPO data
 * @customfunction
*/
async function BenzingaIpo(apikey: string, update_frequency: string, display_titles: boolean, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, exchange?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/benzinga/ipo';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    exchange: exchange
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("BenzingaIpo", BenzingaIpo);


/**
 * Returns the news articles from Benzinga.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} ticker Filter by ticker symbol
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter events by end time using a UNIX timestamp
 * @param {number} [start_at] Filter events by start time using a UNIX timestamp
 * @param {string} [date] Specifies the exact date to get the data for
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return News articles
 * @customfunction
*/
async function BenzingaNews(apikey: string, update_frequency: string, display_titles: boolean, ticker: string, order?: string, end_at?: number, start_at?: number, date?: string, page_size?: number, page?: number, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/benzinga/news';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    ticker: ticker, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    date: date, 
    page_size: page_size, 
    page: page, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("BenzingaNews", BenzingaNews);


/**
 * This endpoint returns a time series of data points for any given ticker.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Time series
 * @customfunction
*/
async function BinanceTimeSeries(apikey: string, update_frequency: string, display_titles: boolean, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/binance/time_series';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("BinanceTimeSeries", BinanceTimeSeries);


/**
 * This endpoint returns a time series of data points for any given ticker.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} ticker Filter by ticker symbol
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Time series
 * @customfunction
*/
async function CryptoTimeSeries(apikey: string, update_frequency: string, display_titles: boolean, interval: string, ticker: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/crypto/time_series';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    interval: interval, 
    ticker: ticker, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("CryptoTimeSeries", CryptoTimeSeries);


/**
 * Returns a list of all datasets available at Finazon.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [code] Filter by Finazon's dataset code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return List of Finazon datasets
 * @customfunction
*/
async function Datasets(apikey: string, update_frequency: string, display_titles: boolean, page_size?: number, page?: number, code?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/datasets';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    page_size: page_size, 
    page: page, 
    code: code
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("Datasets", Datasets);


/**
 * This endpoint returns a time series of data points for any given ticker.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} ticker Filter by ticker symbol
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Time series
 * @customfunction
*/
async function ForexTimeSeries(apikey: string, update_frequency: string, display_titles: boolean, interval: string, ticker: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/forex/time_series';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    interval: interval, 
    ticker: ticker, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("ForexTimeSeries", ForexTimeSeries);


/**
 * Returns a list of supported crypto markets.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return List of crypto markets
 * @customfunction
*/
async function MarketsCrypto(apikey: string, update_frequency: string, display_titles: boolean, page_size?: number, page?: number, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/markets/crypto';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    page_size: page_size, 
    page: page
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("MarketsCrypto", MarketsCrypto);


/**
 * Returns a list of supported stock markets.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [code] Filter by market identifier code (MIC) under ISO 10383 standard
 * @param {string} [name] Filter by market name
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return List of stock markets
 * @customfunction
*/
async function MarketsStocks(apikey: string, update_frequency: string, display_titles: boolean, page_size?: number, page?: number, country?: string, code?: string, name?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/markets/stocks';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    page_size: page_size, 
    page: page, 
    country: country, 
    code: code, 
    name: name
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("MarketsStocks", MarketsStocks);


/**
 * Returns a list of datasets available for the workspace
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return My Datasets
 * @customfunction
*/
async function MyDatasets(apikey: string, update_frequency: string, display_titles: boolean, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/my_datasets';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("MyDatasets", MyDatasets);


/**
 * Returns a list of all publishers available at Finazon.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [code] Filter by Finazon's publisher code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return List of Finazon publishers
 * @customfunction
*/
async function Publishers(apikey: string, update_frequency: string, display_titles: boolean, page_size?: number, page?: number, code?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/publishers';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    page_size: page_size, 
    page: page, 
    code: code
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("Publishers", Publishers);


/**
 * Real-time and historical access to all forms, filings, and exhibits directly from the SEC's EDGAR system.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {number} [page] Pagination size
 * @param {number} [cik_code] Filter by Central Index Key
 * @param {string} [ticker] Filter by ticker
 * @param {string} [form_type] Filter by form types
 * @param {number} [filled_from_ts] Filter by filled time from
 * @param {number} [filled_to_ts] Filter by filled time to
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Filings
 * @customfunction
*/
async function SecArchive(apikey: string, update_frequency: string, display_titles: boolean, page?: number, cik_code?: number, ticker?: string, form_type?: string, filled_from_ts?: number, filled_to_ts?: number, cqs?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/sec/archive';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    page: page, 
    cik_code: cik_code, 
    ticker: ticker, 
    form_type: form_type, 
    filled_from_ts: filled_from_ts, 
    filled_to_ts: filled_to_ts, 
    cqs: cqs, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("SecArchive", SecArchive);


/**
 * Returns a list of market centers.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return List of market centers
 * @customfunction
*/
async function SipMarketCenter(apikey: string, update_frequency: string, display_titles: boolean, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/sip/market_center';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("SipMarketCenter", SipMarketCenter);


/**
 * Returns detailed information on trades executed through the Securities Information Processor (SIP).
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} ticker Filter by ticker symbol
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter trades by end time using a UNIX timestamp
 * @param {number} [start_at] Filter trades by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [tape] Filter by tape
 * @param {string} [market] Filter by market center
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return SIP trades
 * @customfunction
*/
async function SipTrades(apikey: string, update_frequency: string, display_titles: boolean, ticker: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, tape?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/sip/trades';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    ticker: ticker, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    tape: tape, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("SipTrades", SipTrades);


/**
 * This endpoint returns a combination of different data points, such as daily performance, last quote, last trade, minute data, and previous day performance.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Ticker snapshot
 * @customfunction
*/
async function TickerSnapshot(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/ticker/snapshot';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TickerSnapshot", TickerSnapshot);


/**
 * Returns a list of cryptocurrency ticker symbols (pairs). This list is updated daily.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [dataset] Filter by Finazon's dataset code
 * @param {string} [ticker] Filter by ticker symbol
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return List of crypto pairs
 * @customfunction
*/
async function TickersCrypto(apikey: string, update_frequency: string, display_titles: boolean, page_size?: number, page?: number, dataset?: string, ticker?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/tickers/crypto';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    page_size: page_size, 
    page: page, 
    dataset: dataset, 
    ticker: ticker
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TickersCrypto", TickersCrypto);


/**
 * Returns a list of forex ticker symbols (pairs). This list is updated daily.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [ticker] Filter by ticker symbol
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return List of forex ticker symbols
 * @customfunction
*/
async function TickersForex(apikey: string, update_frequency: string, display_titles: boolean, page_size?: number, page?: number, ticker?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/tickers/forex';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    page_size: page_size, 
    page: page, 
    ticker: ticker
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TickersForex", TickersForex);


/**
 * Returns a list of stock ticker symbols. This list is updated daily.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [dataset] Filter by Finazon's dataset code
 * @param {string} [ticker] Filter by ticker symbol
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return List of stock ticker symbols
 * @customfunction
*/
async function TickersStocks(apikey: string, update_frequency: string, display_titles: boolean, page_size?: number, page?: number, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, dataset?: string, ticker?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/tickers/stocks';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    page_size: page_size, 
    page: page, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    dataset: dataset, 
    ticker: ticker
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TickersStocks", TickersStocks);


/**
 * Returns a list of US stock ticker symbols. This list is updated daily.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [ticker] Filter by ticker symbol
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return List of US stock ticker symbols
 * @customfunction
*/
async function TickersUsStocks(apikey: string, update_frequency: string, display_titles: boolean, page_size?: number, page?: number, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, ticker?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/tickers/us_stocks';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    page_size: page_size, 
    page: page, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    ticker: ticker
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TickersUsStocks", TickersUsStocks);


/**
 * This endpoint returns a time series of data points for any given ticker.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Time series
 * @customfunction
*/
async function TimeSeries(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeries", TimeSeries);


/**
 * The Average True Range (ATR) is a volatility indicator that measures the average range of price movement over a specified period, helping traders assess market volatility.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {number} [time_period] Number of periods to average over.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return ATR Technical indicators
 * @customfunction
*/
async function TimeSeriesAtr(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, time_period?: number, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series/atr';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust, 
    time_period: time_period
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeriesAtr", TimeSeriesAtr);


/**
 * Bollinger Bands (BBANDS) are volatility bands placed above and below a moving average, measuring price volatility and helping traders identify potential overbought or oversold conditions.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {string} [series_type] Specifies the price data type on which technical indicator is calculated
 * @param {number} [time_period] Number of periods to average over.
 * @param {any} [sd] Number of standard deviations
 * @param {string} [ma_type] The type of moving average used, such as SMA or EMA
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Overlap Studies
 * @customfunction
*/
async function TimeSeriesBbands(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, series_type?: string, time_period?: number, sd?: any, ma_type?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series/bbands';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust, 
    series_type: series_type, 
    time_period: time_period, 
    sd: sd, 
    ma_type: ma_type
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeriesBbands", TimeSeriesBbands);


/**
 * The Ichimoku Cloud (ICHIMOKU) is a comprehensive trend-following indicator that combines multiple moving averages and support/resistance levels to help traders identify potential entry and exit points, trend direction, and momentum.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {number} [conversion_line_period] The time period used for generating the conversation line
 * @param {number} [base_line_period] The time period used for generating the base line
 * @param {number} [leading_span_b_period] The time period used for generating the leading span B line
 * @param {number} [lagging_span_period] The time period used for generating the lagging span line
 * @param {boolean} [include_ahead_span_period] Indicates whether to include ahead span period
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Overlap Studies
 * @customfunction
*/
async function TimeSeriesIchimoku(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, conversion_line_period?: number, base_line_period?: number, leading_span_b_period?: number, lagging_span_period?: number, include_ahead_span_period?: boolean, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series/ichimoku';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust, 
    conversion_line_period: conversion_line_period, 
    base_line_period: base_line_period, 
    leading_span_b_period: leading_span_b_period, 
    lagging_span_period: lagging_span_period, 
    include_ahead_span_period: include_ahead_span_period
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeriesIchimoku", TimeSeriesIchimoku);


/**
 * The Moving Average (MA) is a smoothing indicator that calculates the average price of a security over a specified period, helping traders identify trends and potential support or resistance levels.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {string} [series_type] Specifies the price data type on which technical indicator is calculated
 * @param {number} [time_period] Number of periods to average over.
 * @param {string} [ma_type] The type of moving average used, such as SMA or EMA
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Overlap Studies
 * @customfunction
*/
async function TimeSeriesMa(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, series_type?: string, time_period?: number, ma_type?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series/ma';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust, 
    series_type: series_type, 
    time_period: time_period, 
    ma_type: ma_type
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeriesMa", TimeSeriesMa);


/**
 * The Moving Average Convergence Divergence (MACD) is a momentum indicator that measures the difference between two moving averages, with a signal line used to identify potential trend reversals and trading opportunities.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {string} [series_type] Specifies the price data type on which technical indicator is calculated
 * @param {number} [fast_period] Number of periods for fast moving average
 * @param {number} [slow_period] Number of periods for slow moving average
 * @param {number} [signal_period] The time period used for generating the signal line
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Momentum Indicators
 * @customfunction
*/
async function TimeSeriesMacd(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, series_type?: string, fast_period?: number, slow_period?: number, signal_period?: number, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series/macd';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust, 
    series_type: series_type, 
    fast_period: fast_period, 
    slow_period: slow_period, 
    signal_period: signal_period
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeriesMacd", TimeSeriesMacd);


/**
 * The On Balance Volume (OBV) indicator is a cumulative volume-based tool used to measure buying and selling pressure, helping traders identify potential price trends and reversals.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {string} [series_type] Specifies the price data type on which technical indicator is calculated
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Volume Indicators
 * @customfunction
*/
async function TimeSeriesObv(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, series_type?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series/obv';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust, 
    series_type: series_type
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeriesObv", TimeSeriesObv);


/**
 * The Relative Strength Index (RSI) is a momentum oscillator that measures the speed and change of price movements, helping traders identify potential overbought or oversold conditions and trend reversals.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {string} [series_type] Specifies the price data type on which technical indicator is calculated
 * @param {number} [time_period] Number of periods to average over
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Momentum Indicators
 * @customfunction
*/
async function TimeSeriesRsi(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, series_type?: string, time_period?: number, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series/rsi';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust, 
    series_type: series_type, 
    time_period: time_period
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeriesRsi", TimeSeriesRsi);


/**
 * The Parabolic SAR (SAR) is a trend-following indicator that calculates potential support and resistance levels based on a security's price and time, helping traders identify potential entry and exit points.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {any} [acceleration] Initial acceleration factor
 * @param {any} [maximum] Maximum value for the acceleration factor
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Overlap Studies
 * @customfunction
*/
async function TimeSeriesSar(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, acceleration?: any, maximum?: any, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series/sar';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust, 
    acceleration: acceleration, 
    maximum: maximum
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeriesSar", TimeSeriesSar);


/**
 * The Stochastic Oscillator (STOCH) is a momentum indicator that compares a security's closing price to its price range over a specified period, helping traders identify potential overbought or oversold conditions and trend reversals.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} interval Interval between two consecutive points in time series
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter output by end time using a UNIX timestamp
 * @param {number} [start_at] Filter output by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {boolean} [prepost] Indicates whether data should be included for extended hours of trading
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [market] Filter by market
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {string} [adjust] Apply adjusting for data (all, splits, dividends, none)
 * @param {number} [fast_k_period] The time period for the fast %K line in the Stochastic Oscillator
 * @param {number} [slow_k_period] The time period for the slow %K line in the Stochastic Oscillator
 * @param {number} [slow_d_period] The time period for the slow %D line in the Stochastic Oscillator
 * @param {string} [slow_kma_type] The type of slow %K Moving Average used
 * @param {string} [slow_dma_type] The type of slow Displaced Moving Average used
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Momentum Indicators
 * @customfunction
*/
async function TimeSeriesStoch(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, interval: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, prepost?: boolean, country?: string, market?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, adjust?: string, fast_k_period?: number, slow_k_period?: number, slow_d_period?: number, slow_kma_type?: string, slow_dma_type?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/time_series/stoch';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    interval: interval, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    prepost: prepost, 
    country: country, 
    market: market, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei, 
    adjust: adjust, 
    fast_k_period: fast_k_period, 
    slow_k_period: slow_k_period, 
    slow_d_period: slow_d_period, 
    slow_kma_type: slow_kma_type, 
    slow_dma_type: slow_dma_type
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("TimeSeriesStoch", TimeSeriesStoch);


/**
 * Returns general information on executed trades.
 * @param {string} apikey API key
 * @param {string} update_frequency Update frequency
 * @param {boolean} display_titles Toggle to display or hide data titles
 * @param {string} dataset Filter by Finazon's dataset code
 * @param {string} ticker Filter by ticker symbol
 * @param {string} [order] Sorting order of the output series
 * @param {number} [end_at] Filter trades by end time using a UNIX timestamp
 * @param {number} [start_at] Filter trades by start time using a UNIX timestamp
 * @param {number} [page_size] Number of items displayed per page in a paginated result
 * @param {number} [page] Specific page of a paginated result to be displayed
 * @param {string} [country] Filter by ISO 3166 alpha-2 code
 * @param {string} [cqs] Filter by cqs symbol
 * @param {string} [cik] Filter by cik code
 * @param {string} [cusip] Filter by cusip code
 * @param {string} [isin] Filter by isin code
 * @param {string} [composite_figi] Filter by composite figi code
 * @param {string} [share_figi] Filter by share class figi code
 * @param {string} [lei] Filter by lei code
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @return Trades
 * @customfunction
*/
async function Trades(apikey: string, update_frequency: string, display_titles: boolean, dataset: string, ticker: string, order?: string, end_at?: number, start_at?: number, page_size?: number, page?: number, country?: string, cqs?: string, cik?: string, cusip?: string, isin?: string, composite_figi?: string, share_figi?: string, lei?: string, invocation?: any): Promise<any[][]> {
  saveCellAddress(invocation.address, update_frequency);
  const endpoint: string = '/trades';
  const params: any = {
    apikey: apikey, 
    update_frequency: update_frequency, 
    display_titles: display_titles, 
    dataset: dataset, 
    ticker: ticker, 
    order: order, 
    end_at: end_at, 
    start_at: start_at, 
    page_size: page_size, 
    page: page, 
    country: country, 
    cqs: cqs, 
    cik: cik, 
    cusip: cusip, 
    isin: isin, 
    composite_figi: composite_figi, 
    share_figi: share_figi, 
    lei: lei
  };
  const response: any = await sendRequest(endpoint, params);
  const toInsert: any[][] =   getDataToInsert(response, display_titles);
  return toInsert;
};
CustomFunctions.associate("Trades", Trades);


