// 2023 - support@basicsoft.ru
// finazon helper functions & parameters


const Host = 'https://api.finazon.io';
const ApiVersion = '/v1.0'; 

export const FormulaPrefix = 'FINAZON'; // formula starts with...

export const UpdateFreq: OptionInfo[] = [ // Update frequency
  { key: "No updates", value: "No updates" },
  { key: "3600",  value: "1 hour" },
  { key: "7200",  value: "2 hours" },
  { key: "14400", value: "4 hours" },
  { key: "28800", value: "8 hours" },
  { key: "86400", value: "1 day" },
];

export const ExcludeEndpoints: string[] = ['/api_usage']; // these endpoints are not displayed in the sidebar
export const PrimaryParams: string[] =    ['order', 'end_at', 'start_at', 'date', 'page_size', 'page', 'prepost', 'instrument_type', 'country', 'tape', 'market', 'code', 'mic']; // optionsl parameters - primary array (for sorting)
const AlwaysToUpperCase: string[] =       ['SIP', 'API']; // words - always to upper case (for Data name)

const NoDataLabel: string = "no data"; // label for case 'no data' (in cell)


export interface DialogInfo {
  show:   boolean;
  text:   string;
}

export interface ApiKeyInfo {
  id:     string;
  name:   string;
  value:  string;
}

export interface OptionInfo {
  key:    string;
  value:  string;
}


// sort endpoint parameters 
export function sortEndpointParameters(parameters: any[]): any[] {
  parameters.sort((a: any, b: any) => { // sort parameters by 'x-order' field 
    let orderA: string = a['x-order'] ?? "0";
    let orderB: string = b['x-order'] ?? "0";
  
    orderA = orderA.replace(/\\/g, '');
    orderB = orderB.replace(/\\/g, '');
  
    let valueA: number = parseInt(orderA, 10);
    let valueB: number = parseInt(orderB, 10);
  
    return valueA - valueB;
  });

  let primaryParams: any = [...PrimaryParams];

  primaryParams.reverse().map((primary: string) => { // sort parameters by primary array
    let index: number = parameters.findIndex((item: any) => item.name === primary);
    if(index == -1){ return; }
    parameters.unshift(parameters.splice(index, 1)[0]); // move item
  });     

  parameters.sort((a: any, b: any) => { // sort parameters by 'required' field 
    let orderA: boolean = a.required ?? false;
    let orderB: boolean = b.required ?? false;   
    return (orderA === orderB ? 0 : orderA ? -1 : 1);
  }); 

  return parameters;
}


// send Get Request to Finazon API
export async function sendRequest(endpoint: string, params?: any) : Promise<any> {
  var url = Host + ApiVersion + endpoint;

  if(!params){ params = {}; }

  for(var key in params){ // concatenate parameters
    if(['update_frequency', 'display_titles'].includes(key)){ continue; } // skip no-material parameters
    if(!params[key] || params[key] == ''){ continue; } // skip null values
    url += (url.endsWith(endpoint) ? '?' : '&') + key + '=' + encodeURIComponent(params[key]);
  }
  
  //console.log(`send Get Request: ${url}`);

  var options = {
    muteHttpExceptions: true,
    method:             'GET',
  }; 

  var response = await fetch(url, options); // HTTP query

  if(![200, 400, 401, 404, 502].includes(response.status)){
    console.log(`Err000 Wrong response code: ${response.status}`);    
    // let text: any = await response.text(); 
    // console.log("response = " + text);    

    return {
      status: "000",
      error: {
        code:       "INVALID_JSON",
        message:    `Wrong response code: ${response.status}`,
        more_info:  ""
      }
    };
  }

  try{
    let data: any = await response.json(); 
    // console.log('data: ' + JSON.stringify(data, null, 2));
    return data; 

  }catch(error){
    return {
      status: "000",
      error: {
        code:       "INVALID_JSON",
        message:    `Wrong HTTP response: ${error}`,
        more_info:  ""
      }
    };

  }

}


// create data name from path 
export function pathToDataName(path: string) : string {
  let dataName = path.replace(/\//g, " ").replace(/_/g, " ");
  const replaceFirstLetter = (letter: string) => { return letter.toUpperCase(); }; // replace first letters
  dataName = dataName.replace(/\b(\w)/g, replaceFirstLetter);
  const replaceWords = (word: string) => { return (AlwaysToUpperCase.includes(word.toUpperCase()) ? word.toUpperCase() : word); }; // replace Words
  dataName = dataName.replace(/\b\w+\b/g, replaceWords);
  return dataName.trim();
}


// create function (formula) name from path 
export function pathToFunctionName(path: string): string {
  var funcName = path.replace(/\//g, ' ').replace(/_/g, ' ');
  function ReplaceToUpper(firstLetters: string): string { return firstLetters.toUpperCase(); }
  funcName = funcName.replace(/\b(\w)/g, ReplaceToUpper).replace(/ /g, '');
  return funcName;
}


// save Cell Address (from formula)
export async function saveCellAddress(address?: string, update_frequency?: string) {
  if(!address || !update_frequency){ return; }

  let update: any = parseInt(update_frequency, 10);
  if(isNaN(update)){ return; } // no need update

  Excel.run(async (context) => {
    const settings = context.workbook.settings;

    var settItem: any = settings.getItemOrNullObject("executionRefresh"); // load
    settItem.load("value");
    await context.sync();
    let execution: boolean = false;
    if(!settItem.isNullObject) { execution = settItem.value; }
    if (execution) { return; } // no need if working refresh

    var settItem: any = settings.getItemOrNullObject("cellsWithFormulaList"); // load
    settItem.load("value");
    await context.sync();
    let cellsList: any = {};
    if(!settItem.isNullObject) { cellsList = settItem.value; }

    const parts: string[] =   address.split("!");
    const sheetName: string = parts[0];
    const cellAddr:  string = parts[1];

    let counter: number = 0;
    for(var name in cellsList){ for(var addr in cellsList[name]){ counter++; } } // cells counter

    if(counter > 1000){ // cells count is too big - optimize
      for(var name in cellsList){ 
        var sheet: any = context.workbook.worksheets.getItemOrNullObject(name);
        sheet.load("name");
        await context.sync();
        if(sheet.isNullObject){ delete cellsList[name]; continue; } 
  
        var hasCells: boolean = false;
  
        for(var addr in cellsList[name]){
          var range: any = sheet.getRange(addr); 
          range.load("formulas"); 
          await context.sync();
          var formula: string = range.formulas[0][0];

          if(!formula.includes(FormulaPrefix)){ delete cellsList[name][addr]; continue; } // formula not found
          hasCells = true;
        }
  
        if(!hasCells){ delete cellsList[name]; continue; } 
      }
    } 

    if(!cellsList[sheetName]){                  // sheet not include to list
      cellsList[sheetName] = {};
      cellsList[sheetName][cellAddr] = {};
    }else if(!cellsList[sheetName][cellAddr]){  // cell not include to list
      cellsList[sheetName][cellAddr] = {};
    }
  
    const date: any = new Date();
    const unixTime: number = Math.floor((date.getTime()/1000)); // get current unix time 

    cellsList[sheetName][cellAddr].period = update;   
    cellsList[sheetName][cellAddr].last =   unixTime;

    settings.add("cellsWithFormulaList", cellsList); // save
    settings.add("enableTimer", true); // enable Timer   
    await context.sync();   

  }).catch(error => {
    console.log("Err003: " + error);

  });
}


// create array for insert to sheet (formula)
export function getDataToInsert(response: any, display_titles: boolean): any[][] {
  let toInsert: any[][] = [];

  if (response.error && response.error.message) {
    let message: string = "";
    if (response.error.message)   { message += response.error.message + '\n'; }
    if (response.error.more_info) { message += response.error.more_info + '\n'; }
    return [["#ERR " + message]];

  } else {
    let responseKey = "data";

    if (response[responseKey]) {
    } else if (Object.keys(response).length == 1) { 
      responseKey = Object.keys(response)[0]; 
    } else {
      return [["#ERR The server returned a response with an invalid data structure."]];
    }

    let header: string[] =  [];

    response[responseKey]?.map((item: any) => {
      let line: any[] = [];
      for (const key in item) {
        if (toInsert.length == 0) { header.push(key); }
        line.push(item[key]); 
      }

      if (toInsert.length == 0 && display_titles) { toInsert.push(header); }
      toInsert.push(line);
    });
  }

  if (toInsert.length == 0) { toInsert.push([NoDataLabel]); } 

  return toInsert;
}


// refresh Formula Data
export function refreshFormulaData(){
  Excel.run(async (context) => {
    const settings = context.workbook.settings;

    try {
      var settItem: any = settings.getItemOrNullObject("enableTimer"); // load
      settItem.load("value");
      await context.sync();
      let enableTimer: boolean = true;
      if(!settItem.isNullObject) { enableTimer = settItem.value; }
      if(!enableTimer){ return; }

      var settItem: any = settings.getItemOrNullObject("executionRefresh"); // load
      settItem.load("value");
      await context.sync();
      let execution: boolean = false;
      if(!settItem.isNullObject) { execution = settItem.value; }
      if (execution) { return; } // no need if working refresh

      settings.add("executionRefresh", true); // save
      await context.sync();

      var settItem: any = settings.getItemOrNullObject("cellsWithFormulaList"); // load
      settItem.load("value");
      await context.sync();
      let cellsList: any = {};
      if(!settItem.isNullObject) { cellsList = settItem.value; }

      var date: any = new Date();
      var unixTime: number = Math.floor((date.getTime()/1000)); // get current unix time

      var needTimer:  boolean = false;

      for(var name in cellsList){ 
        var sheet: any = context.workbook.worksheets.getItemOrNullObject(name);
        sheet.load("name");
        await context.sync();
        if(sheet.isNullObject){ delete cellsList[name]; continue; } 

        var hasCells: boolean = false;

        for(var addr in cellsList[name]){
          var range: any = sheet.getRange(addr); 
          range.load("formulas"); 
          await context.sync();
          var formula: string = range.formulas[0][0];

          if(!formula.includes(FormulaPrefix)){ delete cellsList[name][addr]; continue; } // formula not found

          hasCells = true;

          var period: number = cellsList[name][addr].period;
          if(period == null){ continue; }
    
          if(!needTimer){ needTimer = true; }
    
          var last: number = cellsList[name][addr].last; 
          if(unixTime - last < period){ continue; } // check update period 
    
          range.values = [[formula]];
          cellsList[name][addr].last = unixTime;
        }

        if(!hasCells){ delete cellsList[name]; continue; } 
      }

      settings.add("cellsWithFormulaList", cellsList); // save
      if(!needTimer){ settings.add("enableTimer", false); } // disable Timer
      
    } catch (error) {
      console.log("Err004: " + error);
    }

    settings.add("executionRefresh", false); // save
    await context.sync();

  }).catch(error => {
    console.log("Err005: " + error);

  });
}


// get new UUID
export function newUUID(): string {
  return (String(1e7) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (
      Number(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
    ).toString(16));
}


